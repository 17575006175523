<template>
    <div>
        <v-container class="container--fluid grid-list-md text-center">
            <panel-group />
            <v-row dense>
                <v-col cols="12" sm="12" md="12">
                    <app-widget title="Nombre total de scans par mois">
                        <option-chart slot="widget-content" height="600px" width="100%"
                            :chart-data="getChartOption('bar')" />
                    </app-widget>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="12" sm="12" md="12">
                    <export-json-excel :data="scansvins" :fields="jsonfields" name="exportscanvinsmillesime">
                        Télécharger l'export Excel
                        <v-icon aria-hidden="false" color="green darken-2">
                            mdi mdi-file-excel-outline
                        </v-icon>
                    </export-json-excel>
                    <v-data-table :headers="headers" item-key="nomduvin" group-by="millesime" :items="scansvins"
                        :items-per-page="5" class="elevation-1" />
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
import PanelGroup from '@/views/components/Dashboard/PanelGroup.vue';
import AppWidget from '@/views/widget/AppWidget.vue';
import OptionChart from '@/views/components/Dashboard/OptionChart.vue';
import 'export-json-excel';

export default {
    name: 'Dashboard',
    components: {
        PanelGroup,
        OptionChart,
        AppWidget
    },
    data: () => ({
        chartdata: [],
        jsonfields: [
            { title: 'Nom du vin', name: 'nomduvin' },
            { title: 'Millésime', name: 'millesime' },
            { title: 'Scans', name: 'compteur' }
        ],
        headers: [
            {
                text: 'Nombre de scans pour chaque vin',
                align: 'start',
                sortable: false,
                value: 'name',
            },
            { text: 'Nom du vin', value: 'nomduvin' },
            { text: 'Millésime', value: 'millesime' },
            { text: 'Scans', value: 'compteur' }
        ],
        scansvins: []
    }),
    computed: {

    },
    async mounted() {
        await this.get_currentyearscans();
        await this.get_statmillesime();
    },
    methods: {
        getBarChartOption({ animationDuration } = {}) {
            return {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow',
                    },
                },
                grid: {
                    top: 10,
                    left: '2%',
                    right: '2%',
                    bottom: '3%',
                    containLabel: true,
                },
                xAxis: [
                    {
                        type: 'category',
                        data: [
                            'Janvier',
                            'Février',
                            'Mars',
                            'Avril',
                            'Mai',
                            'Juin',
                            'Juillet',
                            'Aout',
                            'Septembre',
                            'Octobre',
                            'Novembre',
                            'Décembre',
                        ],
                        axisTick: {
                            alignWithLabel: true,
                        },
                    },
                ],
                yAxis: [
                    {
                        type: 'value',
                        axisTick: {
                            show: false,
                        },
                    },
                ],
                series: [
                    {
                        name: 'scans',
                        type: 'bar',
                        stack: 'scan',
                        barWidth: '60%',
                        data: this.chartdata,
                        animationDuration,
                    }
                ],
            };
        },
        getChartOption(option) {
            switch (option) {
                case 'bar':
                    return this.getBarChartOption({ animationDuration: 6000 });
                default:
                    return {};
            }
        },
        async get_currentyearscans() {
            const me = this;
            const url = `${me.$config.API_ENDPOINT}/4DACTION/ksf_web_get_currentyearscans`;
            const bodyFormData = new FormData();
            bodyFormData.append('uuiduser', me.$store.getters.token);
            const response = await me.$axios.post(url, bodyFormData);
            me.chartdata = response.data;
        },
        async get_statmillesime() {
            const me = this;
            const url = `${this.$config.API_ENDPOINT}/4DACTION/ksf_web_getstatmillesime`;
            const bodyFormData = new FormData();
            bodyFormData.append('uuiduser', me.$store.getters.token);
            const response = await me.$axios.post(url, bodyFormData);
            me.scansvins = response.data;
        },
    },
};
</script>
