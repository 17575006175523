var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-col",
    { attrs: { cols: "12", sm: "", md: "6" } },
    [
      _c(
        "v-card",
        { staticClass: "mx-auto" },
        [
          _c(
            "v-list-item",
            { attrs: { "three-line": "" } },
            [
              _c(
                "v-list-item-avatar",
                { attrs: { size: "80" } },
                [
                  _c("v-icon", { attrs: { size: "64", color: _vm.color } }, [
                    _vm._v(" " + _vm._s(_vm.icon) + " "),
                  ]),
                ],
                1
              ),
              _c(
                "v-list-item-content",
                [
                  _c(
                    "v-list-item-title",
                    { staticClass: "text-h6 font-weight-light" },
                    [_vm._v(" " + _vm._s(_vm.title) + " ")]
                  ),
                  _c(
                    "v-list-item-subtitle",
                    [
                      _c("count-to", {
                        staticClass: "text-h6 font-weight-bold",
                        attrs: {
                          "start-val": _vm.start,
                          "end-val": _vm.end,
                          duration: _vm.duration,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }