var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "container--fluid grid-list-md text-center" },
        [
          _c("panel-group"),
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "12", md: "12" } },
                [
                  _c(
                    "app-widget",
                    { attrs: { title: "Nombre total de scans par mois" } },
                    [
                      _c("option-chart", {
                        attrs: {
                          slot: "widget-content",
                          height: "600px",
                          width: "100%",
                          "chart-data": _vm.getChartOption("bar"),
                        },
                        slot: "widget-content",
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "12", md: "12" } },
                [
                  _c(
                    "export-json-excel",
                    {
                      attrs: {
                        data: _vm.scansvins,
                        fields: _vm.jsonfields,
                        name: "exportscanvinsmillesime",
                      },
                    },
                    [
                      _vm._v(" Télécharger l'export Excel "),
                      _c(
                        "v-icon",
                        {
                          attrs: {
                            "aria-hidden": "false",
                            color: "green darken-2",
                          },
                        },
                        [_vm._v(" mdi mdi-file-excel-outline ")]
                      ),
                    ],
                    1
                  ),
                  _c("v-data-table", {
                    staticClass: "elevation-1",
                    attrs: {
                      headers: _vm.headers,
                      "item-key": "nomduvin",
                      "group-by": "millesime",
                      items: _vm.scansvins,
                      "items-per-page": 5,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }