<template>
    <v-row dense class="panel-group">
        <panel-group-item title="Nombre de codes QR disponibles" icon="mdi mdi-qrcode" color="black" :start="nbqrcodedispo"
            :end="nbqrcodedispo" :duration="1" />

        <panel-group-item title="Nombre total de scans" icon="mdi-glass-wine" color="#ac1e44" chart="newVisitis"
            :start="nbscan" :end="nbscan" :duration="1" />
    </v-row>
</template>

<script>
import PanelGroupItem from './PanelGroupItem.vue';

export default {
    name: 'PanelGroup',
    components: {
        PanelGroupItem,
    },
    data: () => ({
        nbqrcodedispo: 0,
        nbscan: 0
    }),
    async mounted() {
        await this.getnbqrcodedispo();
        await this.getnbscantotal();
    },
    methods: {
        async getnbqrcodedispo() {
            const bodyFormData = new FormData();
            bodyFormData.append('uuiduser', this.$store.getters.token);
            const url = `${this.$config.API_ENDPOINT}/4DACTION/ksf_web_refreshqrcodedispo`;
            const response = await this.$axios.post(url, bodyFormData);
            this.nbqrcodedispo = response.data.nbqrcodedispo;
        },
        async getnbscantotal() {
            const bodyFormData = new FormData();
            bodyFormData.append('uuiduser', this.$store.getters.token);
            const url = `${this.$config.API_ENDPOINT}/4DACTION/ksf_web_getscantotal`;
            const response = await this.$axios.post(url, bodyFormData);
            this.nbscan = Number(response.data.content);
        },
    }
};
</script>
