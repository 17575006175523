var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      !_vm.headerHide
        ? _c(
            "v-app-bar",
            { attrs: { flat: "", dense: "", color: "transparent" } },
            [
              _c("v-toolbar-title", { staticClass: "text-h5" }, [
                _vm._v(" " + _vm._s(_vm.title) + " "),
              ]),
              _c("v-spacer"),
              _vm._t("widget-header-action", function () {
                return [
                  _c(
                    "v-btn",
                    { attrs: { icon: "" } },
                    [_c("v-icon", [_vm._v(_vm._s(_vm.icon))])],
                    1
                  ),
                ]
              }),
            ],
            2
          )
        : _vm._e(),
      !_vm.headerHide ? _c("v-divider") : _vm._e(),
      !_vm.paddingHide
        ? _c("v-card-text", [_vm._t("widget-content")], 2)
        : _vm._t("widget-content"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }