var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { staticClass: "panel-group", attrs: { dense: "" } },
    [
      _c("panel-group-item", {
        attrs: {
          title: "Nombre de codes QR disponibles",
          icon: "mdi mdi-qrcode",
          color: "black",
          start: _vm.nbqrcodedispo,
          end: _vm.nbqrcodedispo,
          duration: 1,
        },
      }),
      _c("panel-group-item", {
        attrs: {
          title: "Nombre total de scans",
          icon: "mdi-glass-wine",
          color: "#ac1e44",
          chart: "newVisitis",
          start: _vm.nbscan,
          end: _vm.nbscan,
          duration: 1,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }